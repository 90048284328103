import React, { useState } from 'react'

// Styles
import './navbar.sass'

// Components
import { Header } from './header'
import { NavBody } from './body'

const Navbar = ({ page, dark }) => {
    const [open, setOpen] = useState(false)

    const setPropOpen = (value) => {
        setOpen(value)
    }

    return (
        <div className={`navbar ${open ? 'navbar-active' : 'navbar-deactivate'}`} >
            <Header dark={dark} page={page} setOpen={setPropOpen} />
            <NavBody display={open} />
        </div>
    )
}

export { Navbar }