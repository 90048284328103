import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

// Styles
import './app.sass'

// Pages
import { Home } from './home'
import { Info } from './info'
import { Rooms } from './rooms'
import { Rules } from './rules'
import { Contact } from './contact'
import { Attractions } from './attractions'
import { GalleryRooms } from './gallery/rooms'
import { GalleryHouses } from './gallery/houses'
import { GalleryApartments } from './gallery/apartments'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/regulamin" component={Rules} />
        <Route exact path="/informacje" component={Info} />
        <Route exact path="/kontakt" component={Contact} />
        <Route exact path="/pokoje-domki" component={Rooms} />
        <Route exact path="/atrakcje" component={Attractions} />
        <Route exact path="/galeria/pokoje" component={GalleryRooms} />
        <Route exact path="/galeria/domki" component={GalleryHouses} />
        <Route exact path="/galeria/apartamenty" component={GalleryApartments} />
      </Switch>
    </Router>
  )
}

export default App
