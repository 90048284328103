import React from 'react'

// Components
import { NavLeft } from './nav-left'
import { NavCenter } from './nav-center'
import { NavRight } from './nav-right'


const Header = ({ dark, page, setOpen }) => {

    const setOpenProp = (value) => { setOpen(value) }

    return (
        <header className={dark ? 'dark-header' : ''}>
            <NavLeft page={page} setOpen={setOpenProp} />
            <NavCenter page={page} />
            <NavRight dark={dark} page={page} />
        </header>
    )
}


export { Header }