import React from 'react'

const NavBodyDetailsInfo = () => {
    return (
        <div className="nav-body-details-info">
            <div className="nav-body-details-info-item">
                <div className="nav-body-details-info-item-title">Znajdziesz nas</div>
                <div className="nav-body-details-info-item-text">ul. Główna 284</div>
                <div className="nav-body-details-info-item-text">34-460 Szczawnica</div>
                <div className="nav-body-details-info-item-text">Małopolska</div>
            </div>
            <div className="nav-body-details-info-item">
                <div className="nav-body-details-info-item-title">Kontakt</div>
                <div className="nav-body-details-info-item-text">604 461 606</div>
                <div className="nav-body-details-info-item-text">602 769 342</div>
                <div className="nav-body-details-info-item-text">601 242 802</div>
                <div className="nav-body-details-info-item-text">lidiatecza@interia.pl</div>
                <div className="nav-body-details-info-item-text">ekspres9@wp.pl</div>
            </div>
        </div>
    )
}

export { NavBodyDetailsInfo }