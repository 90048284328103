import React from 'react'

// Styles
import './horizontal.sass'

const HorizontalLine = ({ top, margin, noMargin, bottom, extraClass }) => {
    return (
        <div className={`horizontal-line ${margin ? 'horizontal-line-margin' : ''} ${noMargin ? 'horizontal-line-no-margin' : ''} ${extraClass ? extraClass : ''} `}>
            <div className={`${top ? 'horizontal-line-square' : ''}`}></div>
            <div className="horizontal-line-actual-line"></div>
            <div className={`${bottom ? 'horizontal-line-square' : ''}`}></div>
        </div>
    )
}

export { HorizontalLine }