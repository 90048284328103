import React from 'react'

// Styles
import './info.sass'

// Components
import { VerticalLine } from '../../basic/vertical-line'

const InfoContainer = () => {
    return (
        <div className="info-container">
            <VerticalLine bottom />
            <div className="info-title">
                Ważne<br />
                <span>informacje.</span>
            </div>
            <div className="info-points">
                <div>1. Prosimy o zapoznanie się z REGULAMINEM.</div>
                <div>2. Cisza nocna obowiązuje w godz: 22:00 - 6:00.</div>
                <div>3. Po godz. 22:00 w Apartamencie mogą przebywać tylko osoby zgłoszone.</div>
                <div>4. Doba hotelowa kończy się o godz. 10:00.</div>
                <div>5. We wszytkich pokojach obowiązuje zakaz palenia papierosów.</div>
                <div>6. W Pensjonacie obowiązuje segregacja śmieci.</div>
                <div>7. Prosimy o zakładanie ochraniacza na wypoczynek z funkcją spania.</div>
            </div>
            <VerticalLine top bottom />
            <div className="info-title">
                W ostatnim dniu pobytu<br />
                <span>prosimy o.</span>
            </div>
            <div className="info-points">
                <div> • wyrzucenie śmieci do oznaczonych pojemników na zewnątrz, </div>
                <div>• umycie naczyń,</div>
                <div>• nie pozostawienia resztek jedzenia,</div>
                <div>• nie wyłączenie lodówki,</div>
                <div>• pozostawienie mebli ustawionych w ich pierwotnym miejscu,</div>
                <div>• zamknięcie wszystkich okien.</div>
            </div>
        </div>
    )
}

export { InfoContainer }