import React from 'react'

const ContactItem = ({ title, items }) => {
    return (
        <div className="contact-item">
            <div className="title">{title}</div>
            {items.map((l, i) => <div className="text" key={i}>{l}</div>)}
        </div>
    )
}

export { ContactItem }