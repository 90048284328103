import React from 'react'

// Styles
import './body.sass'

// Components
import { NavBodyLinks } from './links'
import { NavBodyDetails } from './details'

const NavBody = ({ display }) => {
    return (
        <div className={`nav-body ${display ? 'nav-body-active' : 'nav-body-deactivate'}`}>
            <NavBodyLinks />
            <NavBodyDetails />
        </div>
    )
}

export { NavBody }