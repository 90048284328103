import React, { useState, useEffect } from 'react'

// Styles
import './carousel.sass'

const Carousel = ({ limit, path, ext }) => {
    const [img, setImg] = useState(0)


    useEffect(() => {
        const nextPic = setInterval(() => {
            if (limit && limit - 1 === img) return setImg(0)
            setImg(img + 1)
        }, 4000)

        return () => clearInterval(nextPic)
    })

    return (
        <div className="carousel">
            <img className="carousel-image" src={`${process.env.PUBLIC_URL}/images/${path ? path + '/' : ''}${img}.${ext ? ext : 'png'}`} alt={`carousel_${img}`} />
            <div className="carousel-counter">
                <div></div>
                {img + 1} of {limit}
                <div></div>
            </div>
        </div>
    )
}

export { Carousel }