import React from 'react'

const GalleryTitle = ({ title }) => {
    return (
        <div className="gallery__title">
            {title}
        </div>
    )
}

export { GalleryTitle }