import React from 'react'

// Styles
import './rules.sass'

// Components
import { Rule } from './rule'
import { VerticalLine } from '../../basic/vertical-line'

// Rules
const rules = [
    ["Podstawową zameldowania Gościa jest okazanie dokumentu tożsamości ze zdjęciem.", " Pomieszczenia mieszkalne zwane dalej „Apartamentem” wynajmowane są na doby hotelowe.", "Doba hotelowa rozpoczyna się o godz. 14:00 i kończy się o 10:00 dnia następnego."],
    ["Zamiar przedłużenia pobytu na okres przekraczający wskazany w dniu przybycia, Gość powinien zgłosić w Recepcji do godz. 10:00 przed dniem, w którym upływa termin najmu pokoju."],
    ["Gość nie może udostępnić w żaden sposób pokoju osobom trzecim.", "Osoby nie zameldowane w Pensjonacie nie mogą przebywać na terenie Pensjonatu.", "Pensjonat Tęczowe Wzgórze może odmówić przyjęcia gościa, który podczas poprzedniego pobytu rażąco naruszył regulamin, w szczególności wyrządzając szkodę na imieniu Pensjonatu, bądź jego gości, pracownika lub innych osób przebywających w Pensjonacie."],
    ["Pensjonat Tęczowe Wzgórze świadczy usługi zgodnie ze swoją kategorią i standardem. W przypadku zastrzeżeń dotyczących jakości usług Gość jest proszny o jak najszybsze zgłoszenie ich do Recepcji, co umożliwi obsłudze niezwłoczną reakcję.", {
        title: "Pensjonat Tęczowe Wzgórze ma obowiązek zapewnić:", bullets: [
            "a) udzielenia informacji związanych z pobytem",
            "b) bezpieczeństwo pobytu",
            "c) wykonanie niezbędnej naprawy urządzeń w czasie nieobecności Gościa, w jego obecności tylko wówczas, gdy wyrazi takie życzenie,",
            "d) sprawną pod względem technicznym obsługę: w przypdaku wystąpienia usterek, które nie będą mogły być usunięte Pensjonat dołoży starań, aby - w miarę posiadanych możliwości - zmienić pokój lub w inny sposób załagodzić niedogodności."
        ]
    }],
    ["Pensjonat nie ponosi odpowiedzialności za uszkodzenie lub utratę samochodu lub innego pojazdu należącego do Gościa", "Gość ponosi odpowiedzialność materialną za wszelkiego rodzaju uszkodzenia lub zniszczenia przedmiotów wyposażenia i urządzeń technicznych Pensjonatu powstałe z jego winy."],
    ["Ze względu na bezpieczeństwo przeciwpożarowe zabronione jest używanie w pokojach grzałek i żelazek elektrycznych.", "W pensjonacie obowiązuje segregacja śmieci.", "W pokojach obowiązuje zakaz palenia papierosów. Pensjonat wymaga od Gości palenia papierosów w wyznaczonych miejscach.", "Na terenie Pensjonatu obowiązuje zakaz chodzenia w brudnym obuwiu.", "Pensjonat wymaga utrzymania porządku w czasie korzystania z grilla przy altanie. Obowiązuje używanie naczyń jednorazowych oraz zabronione jest wynoszenie naczyń z Apartamentu.", "W Pensjonacie obowiązuje zachowanie ciszy od godz. 22:00 do godz. 6:00"]
]

const RulesContainer = () => {
    return (
        <div className="rules-container">
            <VerticalLine bottom />
            <div className="rules-title">
                Regulamin.
            </div>
            {rules.map((r, i) => <Rule key={i} number={i} points={r} />)}
        </div>
    )
}

export { RulesContainer }