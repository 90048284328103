import React from 'react'

// Styles
import './rental.sass'

// Components
import { Carousel } from '../../../../basic/carousel'
import { VerticalLine } from '../../../../basic/vertical-line'

const HomeOfferRental = () => {
    return (
        <div className="home-offer-rental">
            <div className="home-offer-rental-background" style={{
                background: `url('${process.env.PUBLIC_URL}/bike_background.jpg')`,
                backgroundSize: 'cover'
            }}>
                <VerticalLine bottom />
                <div className="home-rental-title">
                    Moja<br />
                    wypożyczalnia <br />
                    <span>rowerów.</span>
                </div>
                <div className="home-mobile-flex">
                    <div className="home-rental-description">
                        Szczawnica leży w dolinie potoku Grajcarka, który jest prawym dopływem Dunajca. Większa część zabudowy i terenów miasta znajduje się w obrębie Beskidu Sądeckiego, natomiast za doliną potoku Grajcarka wznoszą się już Pieniny. Zabudowa miasta zlokalizowana jest między Pieninami (głównie Małymi Pieninami) a pasmem Radziejowej (Beskid Sądecki).
                        Miasto tradycyjnie dzieli się na dwie „dzielnice”: Szczawnicę Dolną, której główna zabudowa koncentruje się na wysokości 435-480 m n.p.m. oraz Szczawnicę Wyżnią, z główną zabudową na wysokości od 460 do 520 m n.p.m. Pomiędzy nimi znajduje się Centrum, zaś w kierunku północnym, w dolinkę Szczawnego Potoku, do ok. 510 m n.p.m. wspinają się zabudowania dzielnicy zdrojowej. Dwa większe ciągi dawnego osadnictwa rozwinęły się także w dolinkach potoków: Skotnickiego i Sopotnicy, a rozproszone przysiółki sięgnęły wysoko na stoki, a nawet na sam grzbiet (przysiółek Przysłop, 830 m n.p.m.) pasma Radziejowej.
            </div>
                    <div className='home-rental-bike'>
                        <img src={`${process.env.PUBLIC_URL}/images/rental/cyclist.png`} alt="bike" />
                    </div>
                </div>

            </div>
            <div className="home-offer-carousel" style={{ background: '#EFEFEF' }}>
                <Carousel limit={4} />
            </div>
            <footer>
                <div className="home-footer">
                    <div className="footer-copyright">© 2020 Tęczowe Wzgórze</div>
                    <div className="footer-design">
                        Wykonał <a href="http://kiri.dev">Kiri</a>, zaprojektował <a>Riko</a>.
                    </div>
                </div>
            </footer>
        </div>
    )
}

export { HomeOfferRental }