import React from 'react'

// Styles
import './button.sass'

const Button = ({ primary, background, dark, text, onClick, disabled, type, backgroundHover }) => {
    return (
        <button
            className={
                'btn' +
                (primary ? ' btn-primary' : '') +
                (background ? ' btn-primary-background' : '') +
                (dark ? ' btn-dark' : '' +
                    (backgroundHover ? ' btn-primary-backgroundHover' : ''))
            }
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {text}
        </button>
    )
}

export { Button }