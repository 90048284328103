import React from 'react'

// Styles
import './contact.sass'

// Components
import { Navbar } from '../../components/navbar'
import { ContactContainer } from '../../components/pages/contact'

const Contact = () => {
    return (
        <div className="container contact">
            <Navbar page="Kontakt" dark />
            <ContactContainer />
        </div>
    )
}

export { Contact }