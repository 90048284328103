import React from 'react'

// Styles
import './info.sass'

// Components
import { Navbar } from '../../components/navbar'
import { InfoContainer } from '../../components/pages/info'
import { HomeLanding } from '../../components/pages/home/landing'

const Info = () => {
    return (
        <div className="container info" style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/background.png')`
        }}>
            <Navbar page="Informacje" />
            <HomeLanding />
            <InfoContainer />
        </div>
    )
}

export { Info }