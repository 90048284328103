import React from 'react'

// Styles
import './attractions.sass'

// Components
import { Navbar } from '../../components/navbar'
import { AttractionsContainer } from '../../components/pages/attractions'

const Attractions = () => {
    return (
        <div className="container attractions">
            <Navbar page="Atrakcje" dark />
            <AttractionsContainer />
        </div>
    )
}

export { Attractions }