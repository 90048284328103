import React from 'react'

// Styles
import './rooms.sass'

// Components
import { ApartmentsItem } from './apartments'
import { RoomsItem } from './rooms'
import { CottageItem } from './cottage'

const RoomsContainer = () => {
    return (
        <div className="rooms-container">
            <RoomsItem />
            <ApartmentsItem />
            <CottageItem />
        </div>
    )
}

export { RoomsContainer }