import React from 'react'
import { Link } from 'react-router-dom'
// Styles
import './description.sass'

// Components
import { Dots } from '../../../basic/dots'
import { Button } from '../../../basic/button'
import { VerticalLine } from '../../../basic/vertical-line'

const HomeDescription = () => {
    return (
        <div className="home-description">
            <VerticalLine bottom />
            <div className="home-description-title">
                Odkryj nasze klimatyczne <br />
                domki w pięknym górskim <br />
                <span>
                    Pensjonacie Tęczowe Wzgórze.
                </span>
            </div>
            <Dots />
            <div className="home-description-desc">
                Zapraszamy Państwa na wypoczynek w nowo wybudowanym obiekcie "TĘCZOWE- WZGÓRZE" w Szczawnicy.
                Nasz komfortowy dom położony jest w zacisznym miejscu z bajkowym widokiem na góry i Dunajec.
                Oddalony jest od zgiełków i hałasów ulicznych. Doskonale warunki pobytowe,
                czyste powietrze daje Państwu niezapomniany, relaksujący i udany wypoczynek.
                Położenie naszego ośrodka stanowi idealną bazę wypadową spacerów deptakiem do centrum Szczawnicy,
                Krościenka nad Dunajcem i przejścia granicznego przez Słowację.
                Dla chętnych wrażeń i miłośników górskich szlaki na znane szczyty w Pieninach,
                Gorcach i Beskidach oraz spływy Dunajcem są na wyciągnięcie ręki.
                Oferujemy noclegi w przestronnych i wygodnych 2, 3, 4 oraz 5 osobowych pokojach,
                wyposażonych w łazienkę, TV, internet.
                Natomiast na pierwszym piętrze znajduje się kuchnia ogólnie odstępna,
                jest w pełni wyposażona, z dużym salonem prowadząca do tarasu widokowwgo.
            </div>
            <div className="home-description-buttons">
                <Button text="Rezerwuj teraz" dark onClick={() => window.open('https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/')} />
                <Link to="/kontakt"><Button text="Kontakt" dark />
                </Link>
            </div>
        </div>
    )
}

export { HomeDescription }