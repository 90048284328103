import React from 'react'

const NavCenter = ({ page }) => {
    return (
        <div className={`nav-center ${page ? 'nav-center-minus' : ''}`}>
            <div className="nav-center-top">
                <div className="nav-center-top-line"></div>
                <div className="nav-center-top-title">
                    Pensjonat
                </div>
                <div className="nav-center-top-line"></div>
            </div>
            <div className="nav-center-title">Tęczowe wzgórze</div>
        </div>
    )
}

export { NavCenter }