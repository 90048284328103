import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import './details.sass'

// Components
import { NavBodyDetailsInfo } from './info'
import { NavBodyDetailsLinks } from './links'
import { Button } from '../../../basic/button'
import { VerticalLine } from '../../../basic/vertical-line'

const NavBodyDetails = () => {
    return (
        <div className="nav-body-details">
            <NavBodyDetailsLinks />
            <div className="nav-body-details-right">
                <VerticalLine top bottom margin />
                <NavBodyDetailsInfo />
            </div>
            <Button text="Rezerwuj teraz" onClick={() => window.open('https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/')} />
        </div>
    )
}

export { NavBodyDetails }