import React from 'react'

// Styles
import './buildings.sass'

// Components
import { VerticalLine } from '../../../../basic/vertical-line'
import { HomeOfferBuilding } from './building'

const HomeOfferBuildings = () => {
    return (
        <div className="home-buildings">
            <div className="home-buildings-title">Pokoje, Apartamenty, Domek.</div>
            <div className="home-buildings-list">
                <div className="home-buildings-list-column">
                    <HomeOfferBuilding img={4} url="apartamenty" />
                    <HomeOfferBuilding img={5} url="pokoje" />
                </div>
                <VerticalLine top bottom margin />
                <div className="home-buildings-list-column">
                    <HomeOfferBuilding img={6} url="pokoje" />
                    <HomeOfferBuilding img={7} url="apartamenty" />
                </div>
            </div>
        </div>
    )
}

export { HomeOfferBuildings }