import React from 'react'

// Styles
import './attractions.sass'

// Components
import { VerticalLine } from '../../basic/vertical-line'
import { HorizontalLine } from '../../basic/horizontal-line'

const AttractionsContainer = () => {
    return (
        <div className="attractions-container">
            <VerticalLine bottom />
            <div className="attractions-title">
                Wypożyczalnia<br />
                <span>rowerów.</span>
            </div>
            <div className="attractions-items">
                <div className="attractions-item">
                    <div className="left-text">Rower Górski</div>
                    <HorizontalLine top bottom />
                    <div className="right-text">Cena ustalana indywidualnie.</div>
                </div>
                <div className="attractions-item">
                    <div className="left-text">Damka</div>
                    <HorizontalLine top bottom />
                    <div className="right-text">Cena ustalana indywidualnie.</div>
                </div>
            </div>
            <div className="attractions-title">
                Atrakcje.
            </div>
            <div className="attractions-icons">
                <div className="attractions-icons-row">
                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/museum.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Wypożyczalnia buggy</div>
                            <div className="text">Główna 285, Szczawnica</div>
                            <div className="text">Odległość: <span>150m</span></div>
                        </div>
                    </div>

                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/museum.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Spływy Pontonowe</div>
                            <div className="text">Główna 240, Szczawnica</div>
                            <div className="text">Odległość: <span>182m</span></div>
                        </div>
                    </div>

                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/museum.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Park dolny</div>
                            <div className="text">Główna Szczawnica</div>
                            <div className="text">Odległość: <span>375m</span></div>
                        </div>
                    </div>
                </div>

                <div className="attractions-icons-row">
                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/museum.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Sokolica</div>
                            <div className="text">Szczawnica</div>
                            <div className="text">Odległość: <span>1.26km</span></div>
                        </div>
                    </div>


                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/ski.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Wyciąg narciarski w Krościenku</div>
                            <div className="text">Krościenko</div>
                            <div className="text">Odległość: <span>2.44km</span></div>
                        </div>
                    </div>

                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/ski.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Stacja narciarska Palenica</div>
                            <div className="text">Główna 7, Szczawnica</div>
                            <div className="text">Odległość: <span>2.51km</span></div>
                        </div>
                    </div>
                </div>

                <div className="attractions-icons-row">
                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/ski.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Wyciągi narciarskie Szafranówka</div>
                            <div className="text">Szczawnica</div>
                            <div className="text">Odległość: <span>2.52km</span></div>
                        </div>
                    </div>

                    <div className="attractions-icons-item">
                        <div className="icon">
                            <img src={`${process.env.PUBLIC_URL}/icons/museum.png`} />
                        </div>
                        <div className="icons-text">
                            <div className="title">Active Move - kajaki i pontony na Dunajcu</div>
                            <div className="text">Pienińska 13, Szczawnica</div>
                            <div className="text">Odległość: <span>1.12km</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AttractionsContainer }