import React from 'react'

// Styles
import './contact.sass'

// Components
import { ContactMap } from './map'
import { ContactItems } from './items'
import { Button } from '../../basic/button'
import { VerticalLine } from '../../basic/vertical-line'

const ContactContainer = () => {
    return (
        <div className="contact-container">
            <VerticalLine small bottom />
            <div className="contact-title">
                Kontakt.
            </div>
            <ContactItems />
            <Button text="Rezerwuj teraz" dark onClick={() => window.open('https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/')} />
            <ContactMap />
        </div>
    )
}

export { ContactContainer }