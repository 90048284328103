import React from 'react'

// Styles
import './offer.sass'

// Components
import { Dots } from '../../../basic/dots'
import { HomeOfferRental } from './rental'
import { HomeOfferBuildings } from './buildings'

const HomeOffer = () => {
    return (
        <div className="home-offer">
            <Dots />
            <HomeOfferBuildings />
            <HomeOfferRental />
        </div>
    )
}

export { HomeOffer }