import React from 'react'

// Styles
import './rooms.sass'

// Components
import { Navbar } from '../../components/navbar'
import { RoomsContainer } from '../../components/pages/rooms'

const Rooms = () => {
    return (
        <div className="container rooms">
            <Navbar page="Pokoje Domki" dark />
            <RoomsContainer />
        </div>
    )
}

export { Rooms }