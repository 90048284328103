import React from 'react'

// Styles
import './rule.sass'

const Rule = ({ number, points }) => {
    return (
        <div className="rule">
            <div className="rule-title">§ {number + 1}</div>
            <div className="rule-points">
                {points.map((p, x) => {
                    return <div key={x} >
                        <div>{x + 1}. {p.title ? p.title : p}</div>
                        {p.bullets ? <div className="rule-bullets">{p.bullets.map((b, y) => <div key={y}>{b}</div>)}</div> : ''}
                    </div>
                })}
            </div>
        </div >
    )
}

export { Rule }