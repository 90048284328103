import React from 'react'

// Styles
import './rules.sass'

// Components
import { Navbar } from '../../components/navbar'
import { HomeLanding } from '../../components/pages/home/landing'
import { RulesContainer } from '../../components/pages/rules'

const Rules = () => {
    return (
        <div className="container rules" style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/background.png')`
        }}>
            <Navbar page="Regulamin" />
            <HomeLanding />
            <RulesContainer />
        </div>
    )
}

export { Rules }