import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// Styles
import './styles.sass'

// Components
import { GalleryTitle } from './title'
import { Button } from '../../../basic/button'
import { GalleryPictures } from './pictures'
import { VerticalLine } from '../../../basic/vertical-line'
import { HorizontalLine } from '../../../basic/horizontal-line'

const GalleryContainer = ({ title, folder, pics }) => {
    const [show, setShow] = useState()

    const showPicture = (img) => {
        setShow(img)
    }

    return (
        <div className="gallery__container">
            <VerticalLine bottom small />
            <GalleryTitle title={title} />
            <div className="gallery__content">
                <HorizontalLine extraClass="gallery__wide" noMargin />
                <GalleryPictures folder={folder} pics={pics} showPic={showPicture} />
                <div className="gallery__buttons">
                    <Button text="Rezerwuj teraz" dark onClick={() => window.open('https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/')} />
                    <Link to="/kontakt">
                        <Button text="Kontakt" dark />
                    </Link>
                </div>
            </div>
            {show >= 0 && show !== false ? <div className="gallery__picture_active">
                <div className="gallery__picture_active_close" onClick={() => setShow(false)}>&times;</div>
                <img src={`${process.env.PUBLIC_URL}/images/${folder}/${show}.jpg`} alt={show} />
            </div> : ''}
        </div>
    )
}

export { GalleryContainer }