import React from 'react'

// Components
import { Navbar } from '../../../components/navbar'
import { GalleryContainer } from '../../../components/pages/gallery/container'

const GalleryRooms = () => {
    return (
        <div>
            <Navbar page="Pokoje" dark />
            <GalleryContainer title="Pokoje." folder="rooms" pics={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]} />
        </div>
    )
}

export { GalleryRooms }