import React from 'react'

// Components
import { ContactItem } from './item'

const ContactItems = () => {
    return (
        <div className="contact-items">
            <ContactItem title="Znajdziesz nas" items={["ul.Główna 284", "34-460 Szczawnica", "Małopolska"]} />
            <ContactItem title="Kontakt" items={["604 461 606 / 601 242 802", "602 769 342 / 608 670 915", "lidiatecza@interia.pl / ekspres9@wp.pl"]} />
        </div>
    )
}

export { ContactItems }