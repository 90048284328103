import React from 'react'

// Styles
import './map.sass'

// Map
import GoogleMapReact from 'google-map-react'

const ContactMap = () => {
    return (
        <div className="contact-map">
            <div className="map-horizontal-line"></div>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_MAPS_KEY,
                    libraries: ['places'],
                    language: 'pl',
                    region: 'pl',
                }}
                defaultCenter={{
                    lat: 49.43,
                    lng: 20.45
                }}
                defaultZoom={14}
                onGoogleApiLoaded={({ map, maps }) => {
                    const service = new maps.places.PlacesService(map)
                    service.getDetails({
                        placeId: 'ChIJ-9Sz0CEEPkcR2Dw_q7VloKA',
                        fields: ['geometry']
                    }, callback)

                    function callback(place, status) {
                        if (status == maps.places.PlacesServiceStatus.OK) {
                            new maps.Marker({
                                map,
                                position: place.geometry.location,
                            })
                        }
                    }
                }}
                yesIWantToUseGoogleMapApiInternals
            >
            </GoogleMapReact>
        </div >
    )
}

export { ContactMap }