import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { Button } from '../../../basic/button'
import { VerticalLine } from '../../../basic/vertical-line'
import { HorizontalLine } from '../../../basic/horizontal-line'

const ApartmentsItem = () => {
    return (
        <div className="rooms-item">
            <VerticalLine bottom />
            <div className="rooms-item-title">
                Apartamenty.
            </div>
            <div className="rooms-item-content">
                <div className="rooms-item-content-item">
                    <div className="left-text item">Apartament 4-osobowy</div>
                    <HorizontalLine top bottom />
                    <div className="right-text item">Cena ustalana indywidualnie.</div>
                </div>
                <div className="rooms-item-content-item">
                    <div className="left-text">Apartament 5-osobowy</div>
                    <HorizontalLine top bottom />
                    <div className="right-text">Cena ustalana indywidualnie.</div>
                </div>
                <div className="rooms-item-content-item">
                    <div className="left-text">Apartament 2-pokojowy 5-osobowy</div>
                    <HorizontalLine top bottom />
                    <div className="right-text">Cena ustalana indywidualnie.</div>
                </div>
            </div>
            <Link to="/galeria/apartamenty">
                <Button text="Zobacz" dark />
            </Link>
        </div>
    )
}

export { ApartmentsItem }