import React from 'react'

// Components
import { Navbar } from '../../../components/navbar'
import { GalleryContainer } from '../../../components/pages/gallery/container'

const GalleryHouses = () => {
    return (
        <div>
            <Navbar dark page="Domki" />
            <GalleryContainer title="Domki." folder="cabins" pics={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]} />
        </div>
    )
}

export { GalleryHouses }