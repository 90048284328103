import React, { useState } from 'react'

const GalleryPictures = ({ folder, pics, showPic }) => {
    const [show, setShow] = useState(false)

    const onClick = (img) => {
        console.log(img)
        showPic(img)
    }

    return (
        <div className="gallery__pictures">
            <div className="gallery__pictures_row">
                {pics.map((v, i) => {
                    return <img
                        className="gallery__picture"
                        src={`${process.env.PUBLIC_URL}/images/${folder}/${v}.jpg`}
                        onClick={() => onClick(v)}
                        alt={i}
                        key={i}
                    />
                })}
            </div>

        </div>
    )
}

export { GalleryPictures }