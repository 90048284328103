import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import './links.sass'

const NavBodyLinks = () => {
    return (
        <div className="nav-body-links">
            <Link to="/">Home</Link>
            <Link to="/pokoje-domki">Galeria</Link>
            <div className="nav-body-links-small">
                <Link to="/galeria/pokoje">Pokoje</Link>
                <Link to="/galeria/apartamenty">Apartamenty</Link>
                <Link to="/galeria/domki">Domek</Link>
            </div>
            <Link to="/atrakcje">Atrakcje</Link>
            <Link to="/kontakt">Kontakt</Link>
        </div>
    )
}

export { NavBodyLinks }