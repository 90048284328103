import React from 'react'
import { Link } from 'react-router-dom'

const NavBodyDetailsLinks = () => {
    return (
        <div className="nav-body-details-links">
            <Link to="/">Lokalizacja</Link>
            <a href="https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/">Nocowanie</a>
            <Link to="/regulamin">Regulamin</Link>
            <Link to="/informacje">Informacje</Link>
        </div>
    )
}

export { NavBodyDetailsLinks }