import React from 'react'

// Styles
import './carousel.sass'

// Components
import { Carousel } from '../../../basic/carousel'

const HomeCarousel = () => {
    return (
        <div className="home-carousel">
            <div className="home-carousel-line"></div>
            <Carousel limit={4} />
        </div>
    )
}

export { HomeCarousel }
