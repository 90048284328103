import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { Button } from '../../../basic/button'
import { VerticalLine } from '../../../basic/vertical-line'
import { HorizontalLine } from '../../../basic/horizontal-line'

const CottageItem = () => {
    return (
        <div className="rooms-item">
            <VerticalLine bottom />
            <div className="rooms-item-title">
                Domek.
            </div>
            <div className="rooms-item-content">
                <div className="rooms-item-content-item">
                    <div className="left-text">Domek 5-osobowy (2)</div>
                    <HorizontalLine top bottom />
                    <div className="right-text">Cena ustalana indywidualnie.</div>
                </div>
            </div>
            <Link to="/galeria/domki">
                <Button text="Zobacz" dark />
            </Link>
            <div className="rooms-item-note">W naszym obiekcie oferujemy Państwu 27 miejsc noclegowych</div>
        </div>
    )
}

export { CottageItem }