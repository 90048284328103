import React from 'react'

// Components
import { Button } from '../../../basic/button'

const NavRight = ({ dark, page }) => {
    return (
        <div className="nav-right">
            <Button text="Rezerwuj teraz" dark={dark} onClick={() => window.open('https://www.nocowanie.pl/noclegi/szczawnica/pensjonaty/134658/')} />
            { page ? <div className={`nav-right-page ${dark ? 'nav-right-page-dark' : ''}`}>{page}</div> : ''}
        </div>
    )
}

export { NavRight }