import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { Button } from '../../../../../basic/button'

const HomeOfferBuilding = ({ path, img, url }) => {
    return (
        <div className="home-buildings-building">
            <img src={`${process.env.PUBLIC_URL}/images/${path ? path + '/' : ''}${img}.png`} alt={`${img}_building`} />
            <div>
                <Link to={`/galeria/${url}`}>
                    <Button text="Zobacz więcej" primary backgroundHover />
                </Link>
            </div>
        </div>
    )
}

export { HomeOfferBuilding }