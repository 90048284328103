import React from 'react'

// Components
import { Dots } from '../../../basic/dots'

const HomeLanding = () => {
    return (
        <div className="home-landing" id="home-landing">
            <div className="home-landing-top">
                <div></div>
                <span>Pensjonat</span>
                <div></div>
            </div>
            <div className="home-title">
                Tęczowe wzgórze
        </div>
            <div className="home-bottom">
                <Dots />
            </div>
        </div>
    )
}

export { HomeLanding }