import React from 'react'

// Styles
import './line.sass'

const VerticalLine = ({ top, margin, small, bottom }) => {
    return (
        <div className={`vertical-line ${margin ? 'vertical-line-margin' : ''}`} >
            <div className={`${top ? 'vertical-line-square' : ''}`}></div>
            <div className={`vertical-line-actual-line ${small ? 'vertical-line-actual-line-small' : ''}`}></div>
            <div className={`${bottom ? 'vertical-line-square' : ''}`}></div>
        </div>
    )
}

export { VerticalLine }