import React from 'react'

// Styles
import './home.sass'

// Components
import { Navbar } from '../../components/navbar'
import { HomeOffer } from '../../components/pages/home/offer'
import { HomeLanding } from '../../components/pages/home/landing'
import { HomeCarousel } from '../../components/pages/home/carousel'
import { HomeDescription } from '../../components/pages/home/description'

const Home = () => {
    return (
        <div className="container home" style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/background.png')`
        }}>
            <Navbar />
            <HomeLanding />
            <HomeDescription />
            <HomeCarousel />
            <HomeOffer />
        </div>
    )
}

export { Home }